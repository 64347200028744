.list::-webkit-scrollbar {
  display: none !important;
} /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.leftScroll {
  unicode-bidi: bidi-override;
  direction: rtl;
}
.cursorP {
  cursor: pointer !important;
}
.paddingTB20 {
  padding: 20px 0 !important;
}
.paddingT20 {
  padding: 20px 0 0 0 !important;
}
.width-100 {
  width: 100% !important;
}
.width-50 {
  width: 50% !important;
}
.height-100 {
  height: 100% !important;
}
.height-100vh {
  height: 100vh !important;
}
.fontWeightBold {
  font-weight: bold !important;
}
.fontSize1 {
  font-size: 1rem !important;
}
.fontSize09 {
  font-size: 0.9rem !important;
}
.textC {
  text-transform: capitalize !important;
}
.backgroundColorGrey {
  background-color: #9e9e9e0d !important;
}
.bGGrayTCG {
  background-color: #9e9e9e0d !important;
  color: gray;
  border-radius: 7px;
}
.tRbR7 {
  color: red;
  border-radius: 7px;
}
.tgbg7 {
  color: green;
  /* border: 1px solid green; */
  border-radius: 7px;
}
.backgroundColorPrimary {
  background-color: #1e3559;
}
.maxHeight30 {
  max-height: 30px !important;
}
.minHeight30 {
  min-height: 30px !important;
}
.d-flex {
  display: flex !important;
}
.d-flex-column {
  flex-direction: column !important;
}
.d-flex-row {
  flex-direction: row !important;
}
.justifyC {
  justify-content: center !important;
}
.alignC {
  align-items: center !important;
}
.alignFE {
  align-items: flex-end !important;
}
.justifySB {
  justify-content: space-between !important;
}
.dashedDividerRight {
  background: linear-gradient(rgba(0, 0, 0, 0.12) 54%, #fff 0%) right/1px 31px repeat-y !important;
  border: none !important;
}
.dashedDividerBottom {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.12) 54%, #fff 0%) bottom/31px 1px repeat-x !important;
  border: none !important;
}
.justifySA {
  justify-content: space-around !important;
}
.justifySE {
  justify-content: space-evenly !important;
}
.justifyFE {
  justify-content: flex-end !important;
}
.postionR {
  position: relative !important;
}
.positionAB {
  position: absolute !important;
}
.right0 {
  right: 0 !important;
}
.left0 {
  left: 0 !important;
}
.borderRadius13 {
  border-radius: 13px !important;
}
.borderRadius7solid {
  border-radius: 7px !important;
  border: 1px solid rgb(30, 53, 89) !important;
}
.border3Grey {
  border-radius: 3px !important;
  border: 1px solid rgb(30, 53, 89) !important;
}
.borderRadius7dash {
  border-bottom: 1px dashed rgb(30, 53, 89);
}
.bottom0 {
  bottom: 0 !important;
}
.padding5 {
  padding: 5px !important;
}
.padding10 {
  padding: 10px !important;
}
.paddingRLB5 {
  padding: 0 5px 5px 5px;
}
.paddingRLT20 {
  padding: 20px 20px 0 20px;
}
.paddingRL5 {
  padding: 0 5px 0 5px;
}
.paddingRL10 {
  padding: 0 10px 0 10px;
}
.widthFC {
  width: fit-content !important;
}
.heightFC {
  height: fit-content !important;
}
.flexWrap {
  flex-wrap: wrap !important;
}
.heightAuto {
  height: auto !important;
}
.widthAuto {
  width: auto !important;
}
.textDecorU {
  text-decoration: underline !important;
}
.width100px {
  width: 100px;
}
.tCgreen {
  color: green;
}
.tCYellow {
  color: yellow;
}
.mainDivForLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid rgb(43, 89, 240);
  border-color: rgb(43, 89, 240) transparent rgb(43, 89, 240) transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
#scroll::-webkit-scrollbar {
  width: 4.2px;
  background-color: #fafafa;
}
#scroll::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 12px;
}
#scroll::-webkit-scrollbar-track {
  border: 2px solid white;
}
#acscroll::-webkit-scrollbar {
  width: 3.2px;
  background-color: #fafafa;
}
#acscroll::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 12px;
}
#acscroll::-webkit-scrollbar-track {
  border: 2px solid white;
  margin-right: 2px;
}

.fixed_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 40vh;
}

.fixed_header thead tr {
  display: block;
}

.fixed_header thead {
  background: #fafafa;
  color: #3f51b5;
}

.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: center;
  width: 200px;
}
/*---------- For Servicing View -----------*/

.static_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.static_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  height: 35vh;
}
.static_header thead tr {
  display: block;
}

.static_header thead {
  background: #fafafa;
  color: #3f51b5;
}

.static_header th,
.static_header td {
  padding: 5px;
  text-align: center;
  width: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  /* unicode-bidi: bidi-override;
  direction: rtl; */
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}
.noTrack::-webkit-scrollbar-track {
  box-shadow: none !important;
  border-radius: 10px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e3559;
  border-radius: 10px;
}
.scrollBluePrimary::-webkit-scrollbar {
  height: 5px;
  border: 5px solid rgb(232, 227, 227);

  background-color: rgb(232, 227, 227);
}

.scrollBluePrimary::-webkit-scrollbar-thumb {
  background-color: #1e3559;
  background-clip: padding-box;
  border-radius: 15px;
  height: 3px;
}
.scrollBluePrimary::-webkit-scrollbar-thumb:hover {
  background: #1e3559;
}

/* thin */

.scrollBluePrimarythin::-webkit-scrollbar {
  height: 3px;
  border: 5px solid rgb(232, 227, 227);

  background-color: rgb(232, 227, 227);
}

.scrollBluePrimarythin::-webkit-scrollbar-thumb {
  background-color: #1e3559;
  background-clip: padding-box;
  border-radius: 15px;
  height: 1px;
}
.scrollBluePrimarythin::-webkit-scrollbar-thumb:hover {
  background: #1e3559;
}

.scrollBlue::-webkit-scrollbar {
  height: 7px;
  border: 5px solid rgb(59, 105, 176);

  background-color: rgb(59, 105, 176);
}

.scrollBlue::-webkit-scrollbar-thumb {
  background-color: white;
  background-clip: padding-box;
  border: 0.05px solid rgb(59, 105, 176);
  border-radius: 15px;
  height: 5px;
}
.scrollBlue::-webkit-scrollbar-thumb:hover {
  background: white;
}
.scrolllightblue::-webkit-scrollbar {
  height: 7px;
  border: 5px solid #03a9f4;
  border-radius: 0 0 7px 7px;
  background-color: #03a9f4;
}

.scrolllightblue::-webkit-scrollbar-thumb {
  background-color: white;
  background-clip: padding-box;
  border: 0.05px solid #03a9f4;
  border-radius: 15px;
  height: 5px;
}
.scrolllightblue::-webkit-scrollbar-thumb:hover {
  background: white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e3559;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1e3559;
  border-radius: 10px;
}
.displayNone {
  display: none;
}
.textOEllips {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}
.textColorprimary {
  color: #1e3559 !important;
}
.textColorW {
  color: white !important;
}
.padding20 {
  padding: 20px !important;
}
.paddingTB {
  padding: 5px 5px 10px 5px !important;
}
.textCW {
  color: white !important;
}
.marginTB20 {
  margin: 20px 0px !important;
}
.marginT20 {
  margin-top: 20px !important;
}
.marginB10 {
  margin-bottom: 10px !important;
}
.marginR10 {
  margin: 0 10px 0 0px !important;
}

.marginTB10 {
  margin: 10px 0px !important;
}
.marginB5 {
  margin-bottom: 5px !important;
}

.marginB20 {
  margin: 0 0 20px 0px !important;
}
.overflowYA {
  overflow-y: auto !important;
}
.minWidthFC {
  min-width: fit-content !important;
}
.overflowXA {
  overflow-x: auto !important;
}
.overflowH {
  overflow: hidden !important;
}
.minHeight26 {
  min-height: 26px;
}
.maxHeight26 {
  max-height: 26px;
}
.minHeight16 {
  min-height: 16px;
}
.maxHeight16 {
  max-height: 16px;
}
.padding3 {
  padding: 2px;
}

.wordBreak {
  word-break: break-word;
}
.height-100-49 {
  height: calc(100% - 49px);
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
